var indexOf = [].indexOf;

angular.module('app.controllers').controller('TestChatCtrl', ['$scope', '$rootScope', '$location', 'UI', 'APIExhibitors', 'APIChatsAll'].append(function(self, root, $location, UI, APIExhibitors, APIChatsAll) {
  var params;
  params = $location.search();
  self.dataLoad = true;
  if (params && angular.isDefined(params.is_admin)) {
    APIExhibitors.query({
      _entry: 'exhibitors',
      _listing: 'full',
      include_fields: 'exhibitor_name,chataccounts'
    }, function(data) {
      self.exhibitors = data;
      return APIChatsAll.query({
        include_offline: 1
      }, function(data) {
        self.meetings = data;
        return self.dataLoad = false;
      });
    });
    return self.dataChats = function(exhibitor, account_id = null, chataccounts = false) {
      var ref;
      return (ref = self.meetings) != null ? ref.filter(function(meet) {
        var ids, ref1, ref2;
        // chat_accounts feature is turned on
        if (meet.user_ids != null) {
          ids = meet.user_ids.filter(function(user_id) {
            return user_id.user_id === exhibitor.id;
          });
          return ids.length > 0;
        }
        if (chataccounts && ((ref1 = exhibitor.chataccounts) != null ? ref1.length : void 0) > 0) {
          // return depending manually added accounts in exhibitor data
          return ref2 = meet.account.id, indexOf.call(exhibitor.chataccounts, ref2) >= 0;
        }
        if (!account_id) {
          // return all exhibito accounts
          return meet.user.id === exhibitor.id;
        }
        // return selected account
        return meet.user.id === exhibitor.id && meet.account.id === account_id;
      }) : void 0;
    };
  } else {
    return $location.path("/");
  }
}));
