angular.module('app.controllers').controller 'TestChatCtrl', [
  '$scope', '$rootScope', '$location', 'UI', 'APIExhibitors', 'APIChatsAll'
].append (self, root, $location, UI, APIExhibitors, APIChatsAll) ->

  params = $location.search()

  self.dataLoad = true

  if params && angular.isDefined(params.is_admin)

    APIExhibitors.query {_entry: 'exhibitors', _listing: 'full', include_fields: 'exhibitor_name,chataccounts'}, (data) ->
      self.exhibitors = data

      APIChatsAll.query {include_offline: 1}, (data) ->
        self.meetings = data
        
        self.dataLoad = false

    self.dataChats = (exhibitor, account_id = null, chataccounts = false) ->    
      return self.meetings?.filter (meet) ->
        # chat_accounts feature is turned on
        if meet.user_ids?
          ids = meet.user_ids.filter (user_id) ->
            return user_id.user_id == exhibitor.id
          return ids.length > 0

        # return depending manually added accounts in exhibitor data
        return meet.account.id in exhibitor.chataccounts if chataccounts and exhibitor.chataccounts?.length > 0
        # return all exhibito accounts
        return meet.user.id == exhibitor.id unless account_id
        # return selected account
        return meet.user.id == exhibitor.id && meet.account.id == account_id

  else
    $location.path("/")
